import { ActionIcon, Box, Button, Center, CloseButton, Divider, Grid, Group, SegmentedControl, Tabs, TabsList, TabsTab, Text, TextInput } from "@mantine/core";
import Modal, { Props as ModalProps } from "./Modal";
import { useForm } from "@mantine/form";
import { useEffect, useState, ReactNode } from "react";
import { client } from "../../lib/axiosClient";
import { IconCheck, IconPlus, IconX } from "@tabler/icons-react";
import { notifications } from "@mantine/notifications";
import Cookies from "universal-cookie";
import classes from './../../styles/PricesDelivery.module.css';
import Wilayas from './../../helper/wilayas.json';
import { useDeliveryPrices, useGroupsDeliveryPrices } from "../../api";
import { useTranslation } from "react-i18next";
import { DeleteGroupDeliveryPrice } from "./DeleteGroupDeliveryPrice";

const cookies = new Cookies(null, { path: '/' });

type Props = {
    setSelectedData?: (id: string) => void;
    data?: any;
    refetchData?: () => void;
} & ModalProps;

function generateRandomString(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const UpdatePricesDelivery = (props: Props) => {
    const { t } = useTranslation();
    const [openAddModal, setOpenAddModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [deletedGroup, setDeletedGroup] = useState("");
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState('');
    const [dataTabs, setDataTabs] = useState<{label: string; value: string;}[]>([]);
    const [dataTabs2, setDataTabs2] = useState<{label: string; value: string;}[]>([
        {label: "tap 01", value: "tap1"},
        {label: "tap 02", value: "tap2"},
        {label: "tap 03", value: "tap3"}
    ]);
    const {onSubmit, reset, getInputProps, setValues, values, insertListItem} = useForm({
        initialValues: {wilayas: []}
    });
    const {onSubmit: onSubmit2, reset: reset2, getInputProps: getInputProps2, setValues: setValues2, values: values2} = useForm({
        initialValues: {name: ""}
    });
    const {loading: loadingDeliveryPrices, fetchData: getDeliveryPrices, data: dataDeliveryPrices, refetch} = useDeliveryPrices()
    const {fetchData: getGroupsDeliveryPrices, data: dataGroupsDeliveryPrices, refetch: refetchGroupsDeliveryPrices} = useGroupsDeliveryPrices()
    
    useEffect(() => {
        if (props.opened) {
            getGroupsDeliveryPrices()
        }
    }, [props.opened])

    useEffect(() => {
        if (props.opened && value !== "") {
            getDeliveryPrices({group: value})
        }
    }, [value])

    useEffect(() => {
        if (props.opened && dataGroupsDeliveryPrices.length >= 0) {
            let listGroupsDeliveryPrices: {label: string; value: string;}[] = []
            for (let i = 0; i < dataGroupsDeliveryPrices.length; i++) {
                const element = dataGroupsDeliveryPrices[i];
                if (i == 0) {
                    setValue(element.code)
                }
                listGroupsDeliveryPrices.push({label: element.name, value: element.code})
            }
            setDataTabs([...listGroupsDeliveryPrices])
        }
    }, [dataGroupsDeliveryPrices])

    useEffect(() => {
        if (props.opened) {
            if (dataDeliveryPrices.length > 0) {
                let newData: any = []
                dataDeliveryPrices?.map((item: any) => {
                    newData.push({
                        _id: item._id, name: item.name, code: item.code,
                        desk_fee: item.desk_fee, home_fee: item.home_fee,
                        group: item?.group || {code: value, name: getLabel(value)}
                    })
                })

                if (dataDeliveryPrices.length < Wilayas.length) {
                    for (let i = 0; i < Wilayas.length; i++) {
                        const wilaya = Wilayas[i];
                        const index = dataDeliveryPrices.findIndex((item: any) => item.code == wilaya.code)
                        if (index < 0) {
                            newData.push({
                                _id: "", name: wilaya.name, code: wilaya.code,
                                desk_fee: 0, home_fee: 0,
                                group: {code: value, name: getLabel(value)}
                            })
                        }
                    }
                }
    
                setValues({
                    wilayas: newData
                })
            } else {
                if (Wilayas.length > 0) {
                    
                    let newData: any = []
                    Wilayas.map((item: any) => {
                        newData.push({
                            _id: "", name: item.name, code: item.code,
                            desk_fee: 0, home_fee: 0,
                            group: {code: value, name: getLabel(value)}
                        })
                    })
        
                    setValues({
                        wilayas: newData
                    })
                }
            }
        }
    }, [dataDeliveryPrices])

    const onSubmitForm = ({wilayas}: any) => {
        setLoading(true)
        client.post(`/delivery-price`, wilayas, {
            headers: {
                'Accept': 'application/json',
                'Authorization': cookies.get('token') || ""
            }
        })
        .then(({data}) => {
            notifications.show({ message: t('modals.updatePricesDelivery.label01'), color: '#fff' });
            setLoading(false)
            refetch({group: value})
        })
        .catch((error) => {
            notifications.show({ message: t('modals.updatePricesDelivery.label02'), color: '#fff' });
            console.log(error)
            setLoading(false)
        });
    }

    
    const onSubmitForm2 = ({name}: any) => {
        console.log("addGroup");
        const randID = generateRandomString(3)
        setDataTabs((pre) => [...pre, {
            label: name,
            value: `custom${randID}`
        }])
        setValue(`custom${randID}`)
        closeModal2()
    }

    const getLabel = (value: string) => {
        const row = dataTabs.filter(item => item.value == value)
        if (row.length > 0) {
            return row[0].label
        } else {
            return ""
        }
    }

    const removeGroup = (group: string) => {
        setDeletedGroup(group)
        setOpenDeleteModal(true)
    }
    
    const closeModal = () => {
        reset();
        props.onClose();
        setLoading(false)
        setOpenDeleteModal(false)
        setDeletedGroup('')
        setValue('default')
        setDataTabs([])
    };

    const closeModal2 = () => {
        reset2();
        setOpenAddModal(false)
    };

    console.log(loading, loadingDeliveryPrices, loading || loadingDeliveryPrices);
    
    return (
        <>
            <Modal
                {...props} onClose={closeModal} loading={loading || loadingDeliveryPrices} size="lg"
                footer={
                    <Box py={16} px={20} bg="slate.0">
                        <Group justify={"flex-end"} gap={"xs"}>
                            <Button color={'gray'} variant="outline" rightSection={<IconX size={15} />} bg="white" onClick={closeModal}>{t('modals.cancelling')}</Button>
                            <Button color={'#323232'} rightSection={<IconCheck size={15} />} type="submit" form="submit_form">{t('modals.confirmation')}</Button>
                        </Group>
                    </Box>
                }
            >
                <Group bg={"#fff"} wrap="nowrap" justify="flex-start" gap={6} align="center">
                    <SegmentedControl
                        withItemsBorders={false}
                        fullWidth
                        value={value}
                        onChange={setValue}
                        data={dataTabs.map((item, index) => { 
                            return {
                                label: (
                                    <Center style={{ gap: 5 }}>
                                        <span>{item.label}</span>
                                        <CloseButton size="sm" variant="subtle" radius={"xl"} onClick={() => removeGroup(item.value)} />
                                    </Center>
                                ),
                                value: item.value
                            }
                        })}
                        styles={{
                            root: {
                                borderBottom: "1px solid #E0E2E7",
                                background: "#fff",
                                height: 50,
                                alignItems: "center",
                                overflowX: 'auto',
                                justifyContent: 'flex-start',
                                gap: 10,
                                borderRadius: 0
                            },
                            indicator: { display: "none" },
                            control: {
                                flex: "none",
                                border: "1px solid #E0E2E7",
                                borderRadius: 1
                            }
                        }}
                        classNames={{ control: classes.control }}
                    />
                    <ActionIcon variant="subtle" color="gray" onClick={() => setOpenAddModal(true)} me={4}>
                        <IconPlus size={14} />
                    </ActionIcon>
                </Group>
                <Box style={{padding: 20}}>
                    <form onSubmit={onSubmit(onSubmitForm)} id="submit_form">
                        {values?.wilayas.map((item: any, index) => (
                            <Grid gutter={20} justify="flex-start">
                                <Grid.Col span={{base: 12, md: 4}} style={{alignSelf: "flex-end"}}>
                                    <TextInput
                                        placeholder={t('modals.updatePricesDelivery.label01')}
                                        label={t('modals.updatePricesDelivery.placeholder01')}
                                        readOnly={true}
                                        
                                        {...getInputProps(`wilayas.${index}.name`)}
                                    />
                                </Grid.Col>
                                <Grid.Col span={{base: 12, md: 4}} >
                                    <TextInput
                                        placeholder={t('modals.updatePricesDelivery.label02')}
                                        label={t('modals.updatePricesDelivery.placeholder02')}
                                        
                                        {...getInputProps(`wilayas.${index}.desk_fee`)}
                                    />
                                </Grid.Col>
                                <Grid.Col span={{base: 12, md: 4}} >
                                    <TextInput
                                        placeholder={t('modals.updatePricesDelivery.label03')}
                                        label={t('modals.updatePricesDelivery.placeholder03')}
                                        
                                        {...getInputProps(`wilayas.${index}.home_fee`)}
                                    />
                                </Grid.Col>
                            </Grid>
                        ))}
                    </form>
                </Box>
            </Modal>

            <Modal
                opened={openAddModal} onClose={closeModal2} size="md" title={"إضافة تسعيرة خاصة"}
                footer={
                    <Box py={16} px={20} bg="slate.0">
                        <Group justify={"flex-end"} gap={"xs"}>
                            <Button variant="outline" color="gray" rightSection={<IconX size={15} />} bg="white" onClick={closeModal}>{t('modals.cancelling')}</Button>
                            <Button color={'#323232'} rightSection={<IconCheck size={15} />} type="submit" form="submit_form2">{"إضافة"}</Button>
                        </Group>
                    </Box>
                }
            >
                <Box style={({ colors }) => ({padding: 20})}>
                    <form onSubmit={onSubmit2(onSubmitForm2)} id="submit_form2">
                        <Grid gutter={20} justify="flex-start">
                            <Grid.Col span={{base: 12, md: 4}} style={{alignSelf: "flex-end"}}>
                                <TextInput
                                    placeholder={"اسم التسعيرة"}
                                    label={"اسم التسعيرة"}
                                    
                                    {...getInputProps2(`name`)}
                                />
                            </Grid.Col>
                        </Grid>
                    </form>
                </Box>
            </Modal>
            <DeleteGroupDeliveryPrice
                data={deletedGroup} opened={openDeleteModal}
                refetchData={() => {
                    refetchGroupsDeliveryPrices()
                    refetch({group: 'default'})
                }}
                onClose={() => setOpenDeleteModal(false)}
            />
        </>
    );
};