import { Carousel } from '@mantine/carousel';
import { ActionIcon, Badge, Box, Button, CopyButton, Drawer, DrawerProps, Grid, Group, Image, Stack, Tabs, Text, Timeline, Tooltip, rem } from '@mantine/core';
import { IconCheck, IconCircleCheck, IconCircleX, IconCopy, IconPrinter } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sortedData } from '../../lib/sort';
import { useUsers } from '../../api';
import Avatar from 'react-avatar';

type Props = {
    data?: any;
    setSelectedData?: (id: string) => void;
} & DrawerProps;

export function ShowOrderDrawer(props: Props) {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState<string | null>('tab1');
    const {loading, error, data: dataUser, refetch} = useUsers()
    const [order, setOrder] = useState<any>(null);

    useEffect(() => {
        if ("data" in props && props.data !== null) {
            setOrder(props?.data)
        }
    }, [props.data])

    const closeModal = () => {
        props.onClose();
    };
    const getUser = (userID: string) => {
        const user = dataUser?.filter((item: any) => item._id == userID)
        if (user.length > 0) {
            return user[0].name as string
        } else {
            return ""
        }
    }

    const reverseTimeLine = (data: any) => {
        const reversed = sortedData(data)

        return reversed
    }

    return (
        <Drawer 
            {...props} onClose={closeModal}
            styles={{
                body: {background: "#eee"},
                content: {background: "#eee"},
                header: {background: "#fff", padding: 0},
                title: {height: 60}
            }}
            title={
                <Tabs
                    defaultValue={activeTab} value={activeTab} onChange={setActiveTab}
                    styles={{
                        tab: {
                            height: 60,
                            paddingLeft: 0,
                            paddingRight: 0
                        },
                        list: {
                            flexWrap: 'nowrap',
                            // overflowX: 'scroll',
                            gap: 10
                        }
                    }}
                >
                    <Tabs.List>
                        <Tabs.Tab value="tab1" >معلومات الطلب</Tabs.Tab>
                        <Tabs.Tab value="tab2" >حالات الطلب</Tabs.Tab>
                    </Tabs.List>
                </Tabs>
            }
        >
            <Tabs defaultValue={activeTab} value={activeTab} onChange={setActiveTab} >
                <Tabs.Panel value="tab1" >
                    <Grid gutter={15} justify="center" h={"100%"}>
                        <Grid.Col span={12} mt={20}>
                            <Group justify='space-between' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                                <Text fw={400} c={"gray.7"}>{t('drawer.order.label01')}</Text>
                                <Text fw={600}>{order?.product?.name}</Text>
                            </Group>
                        </Grid.Col>
                        <Grid.Col span={12} >
                            <Group justify='space-between' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                                <Text fw={400} c={"gray.7"}>{t('drawer.order.label02')}</Text>
                                <Text fw={600}>{order?.fullName}</Text>
                            </Group>
                        </Grid.Col>
                        <Grid.Col span={12} >
                            <Group justify='space-between' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                                <Text fw={400} c={"gray.7"}>{t('drawer.order.label03')}</Text>
                                <Text fw={600}>{order?.phone}</Text>
                            </Group>
                        </Grid.Col>
                        <Grid.Col span={12} >
                            <Group justify='space-between' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                                <Text fw={400} c={"gray.7"}>{t('drawer.order.label04')}</Text>
                                <Text fw={600}>{order?.price * order?.quantity} {t("currency")}</Text>
                            </Group>
                        </Grid.Col>
                        <Grid.Col span={12} >
                            <Group justify='space-between' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                                <Text fw={400} c={"gray.7"}>{t('drawer.order.label05')}</Text>
                                <Text fw={600}>{order?.quantity}</Text>
                            </Group>
                        </Grid.Col>

                        
                        {process.env.REACT_APP_PAGE_COUPON_DISPLAY == "true" && order?.coupon && order?.coupon !== ""
                            ? <>
                                <Grid.Col span={12} >
                                    <Group justify='space-between' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                                        <Text fw={400} c={"gray.7"}>{t('drawer.order.label19')}</Text>
                                        <Text fw={600}>{order?.coupon}</Text>
                                    </Group>
                                </Grid.Col>
                                <Grid.Col span={12} >
                                    <Group justify='space-between' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                                        <Text fw={400} c={"gray.7"}>{t('drawer.order.label20')}</Text>
                                        <Group>
                                            <Text fw={600}>{Math.round(((order?.price * 100 / (100 - order?.discountCoupon)) * order?.quantity * order?.discountCoupon) / 100)} {t("currency")}</Text>
                                            <Badge ta={'start'} radius={'sm'} color='red' variant='filled'>- {order?.discountCoupon}%</Badge>
                                        </Group>
                                    </Group>
                                </Grid.Col>
                            </>
                            : null
                        }

                        <Grid.Col span={12} >
                            <Group justify='space-between' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                                <Text fw={400} c={"gray.7"}>{t('drawer.order.label06')}</Text>
                                <Text fw={600}>{
                                    order?.status === "pending" ? <Badge radius={'sm'} color='yellow' variant='light'>{t('drawer.order.badge01')}</Badge> 
                                    : order?.status === "confirmed" ? <Badge radius={'sm'} color='green' variant='light'>{t('drawer.order.badge02')}</Badge> 
                                        : order?.status === "closed" ? <Badge radius={'sm'} color='red' variant='light'>{t('drawer.order.badge03')}</Badge>
                                        : order?.status === "abandoned" ? <Badge radius={'sm'} color='orange' variant='light'>{t('drawer.order.badge04')}</Badge> : null
                                }</Text>
                            </Group>
                        </Grid.Col>
                        <Grid.Col span={12} >
                            <Group justify='space-between' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                                <Text fw={400} c={"gray.7"}>{t('drawer.order.label07')}</Text>
                                <Text fw={600}>{order?.deliveryPrice} {t("currency")}</Text>
                            </Group>
                        </Grid.Col>
                        <Grid.Col span={12} >
                            <Group justify='space-between' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                                <Text fw={400} c={"gray.7"}>{t('drawer.order.label08')}</Text>
                                <Text size='sm' fw={500} c={"gray.8"}>{dayjs(order?.createdAt).format("HH:mm YYYY-MM-DD")}</Text>
                            </Group>
                        </Grid.Col>
                        <Grid.Col span={12} >
                            <Group justify='space-between' bg={"#fff"} gap={5} p={10} style={{borderRadius: 5}}>
                                <Text fw={400} c={"gray.7"}>{t('drawer.order.label09')}</Text>
                                <Text size='sm' fw={500} c={"gray.8"}>{
                                    order?.typeFee === "home_fee" 
                                        ? <Badge radius={'sm'} color='gray' variant='outline'>{t('drawer.order.badge05')}</Badge>
                                        : <Badge radius={'sm'} color='gray' variant='outline'>{t('drawer.order.badge06')}</Badge>
                                }</Text>
                            </Group>
                        </Grid.Col>
                        <Grid.Col span={12} >
                            <Group justify='space-between' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                                <Text fw={400} c={"gray.7"}>{t('drawer.order.label10')}</Text>
                                <Text size='sm' fw={500} c={"gray.8"}>{order?.state}</Text>
                            </Group>
                        </Grid.Col>
                        <Grid.Col span={12} >
                            <Group justify='space-between' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                                <Text fw={400} c={"gray.7"}>{t('drawer.order.label11')}</Text>
                                <Text size='sm' fw={500} c={"gray.8"}>{order?.city}</Text>
                            </Group>
                        </Grid.Col>
                        <Grid.Col span={12} >
                            <Group justify='space-between' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                                <Text fw={400} c={"gray.7"}>{t('drawer.order.label12')}</Text>
                                <Text size='sm' fw={500} c={"gray.8"}>{order?.address}</Text>
                            </Group>
                        </Grid.Col>
                        {order?.referralCode && order?.referralCode !== ""
                            ? <Grid.Col span={12} >
                                <Group justify='space-between' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                                    <Text fw={400} c={"gray.7"}>{"المسوق:"}</Text>
                                    <Text size='sm' fw={500} c={"gray.8"}>{order?.marketer?.name}</Text>
                                </Group>
                            </Grid.Col>
                            : null
                        }
                        {/* <Grid.Col span={12} >
                            <Group justify='space-between' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                                <Text fw={400} c={"gray.7"}>{t('drawer.order.label21')}</Text>
                                <Text size='sm' fw={500} c={"gray.8"}>{order?.note}</Text>
                            </Group>
                        </Grid.Col> */}

                        <Grid.Col span={12} >
                            <Group justify='flex-start' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                                <Stack gap={10} w={"100%"}>
                                    <Group justify='space-between' >
                                        <Text fw={400} c={"gray.7"}>{t('drawer.order.label13')}</Text>
                                        <Text size='sm' fw={500} c={"gray.8"}>{
                                            order?.deliveryCompany?.name === "Yalidine"
                                                ? <Image src={"/yalidine-logo.png"} width={"auto"} height={26} />
                                                : null
                                        }</Text>
                                    </Group>

                                    <Group justify='space-between' >
                                        <Text fw={400} c={"gray.7"}>{t('drawer.order.label14')}</Text>
                                        <Text size='sm' fw={500} c={"gray.8"}>{
                                            order?.deliveryCompany?.name === "Yalidine"
                                                ? <CopyButton value={order?.deliveryCompany?.trackingCode} timeout={2000}>
                                                    {({ copied, copy }) => (
                                                        <Tooltip label={copied ? t('drawer.order.copiedLabel') : t('drawer.order.copyLabel')} withArrow position="top">
                                                            <Button
                                                                color={copied ? 'teal' : '#dc3545'}
                                                                variant="outline" onClick={copy} size='xs'
                                                                leftSection={copied ? (<IconCheck style={{ width: rem(14) }} />) : (<IconCopy style={{ width: rem(14) }} />)}
                                                            >
                                                                {order?.deliveryCompany?.trackingCode}
                                                            </Button>
                                                        </Tooltip>
                                                    )}
                                                </CopyButton>
                                                : null
                                        }</Text>
                                    </Group>
                                    {order?.deliveryCompany?.bordereau && order?.deliveryCompany?.bordereau !== ""
                                        ? <Group justify='space-between' >
                                            <Text fw={400} c={"gray.7"}>{t('drawer.order.label15')}</Text>
                                            <ActionIcon
                                                color={'teal'} variant="outline"
                                                component='a' target='_blank'
                                                href={order?.deliveryCompany?.bordereau}
                                            >
                                                <IconPrinter style={{ width: rem(14) }} />
                                            </ActionIcon>
                                        </Group>
                                        : null
                                    }
                                </Stack>
                            </Group>
                        </Grid.Col>

                        {order?.colors && order?.colors?.length > 0
                            ? <Grid.Col span={12} >
                                <Group justify='space-between' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                                    <Text fw={400} c={"gray.7"}>{t('drawer.order.label16')}</Text>
                                    <Group justify='flex-start' align='center' gap={5}>
                                        {order?.colors?.map((item: string, index: number) => (
                                            <Box key={index} w={20} h={20} bg={item} style={{border: "1px solid #ddd", borderRadius: 3}} />
                                        ))}
                                    </Group>
                                </Group>
                            </Grid.Col>
                            : null
                        }
                        {order?.sizes && order?.sizes?.length > 0
                            ? <Grid.Col span={12} >
                                <Group justify='space-between' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                                    <Text fw={400} c={"gray.7"}>{t('drawer.order.label17')}</Text>
                                    <Group justify='flex-start' align='center' gap={5}>
                                        {order?.sizes?.map((item: string, index: number) => (
                                            item !== "" && <Badge key={index} variant="outline" color="gray">{item}</Badge>
                                        ))}
                                    </Group>
                                </Group>
                            </Grid.Col>
                            : null
                        }
                    </Grid>
                </Tabs.Panel>

                <Tabs.Panel value="tab2" >
                    {order?.statusTimeLine?.length > 0
                        ? <Timeline mt={50} active={order?.statusTimeLine?.length - 1} bulletSize={18} lineWidth={1.5}>
                            {reverseTimeLine(order?.statusTimeLine)?.map((item: any, index: number) => (
                                <Timeline.Item
                                    key={index}
                                    //@ts-ignore
                                    title={
                                        <Group justify='space-between' align='center'>
                                            <Text c="dimmed" size="sm" display={'flex'} style={{alignItems: 'center'}}>
                                                <Avatar name={getUser(item?.userID)} size="20" round={true} style={{marginLeft: 5}} />
                                                {getUser(item?.userID)}
                                            </Text>
                                            <Group justify='flex-end' align='center' gap={5}>
                                                {item?.status
                                                    ? <>{
                                                        item?.status === "pending" ? <Badge radius={'sm'} color='yellow' variant='light'>{t('drawer.order.badge01')}</Badge> 
                                                        : item?.status === "confirmed" ? <Badge radius={'sm'} color='green' variant='light'>{t('drawer.order.badge02')}</Badge> 
                                                            : item?.status === "closed" ? <Badge radius={'sm'} color='red' variant='light'>{t('drawer.order.badge03')}</Badge>
                                                            : item?.status === "abandoned" ? <Badge radius={'sm'} color='orange' variant='light'>{t('drawer.order.badge04')}</Badge>
                                                                : item?.status === "attempt-1" ? <Badge radius={'sm'} color='pink' variant='light'>{"المحاولة الاولى"}</Badge>
                                                                : item?.status === "attempt-2" ? <Badge radius={'sm'} color='orange' variant='light'>{"المحاولة الثانية"}</Badge>
                                                                : item?.status === "attempt-3" ? <Badge radius={'sm'} color='violet' variant='light'>{"المحاولة الثالثة"}</Badge>
                                                                : item?.status === "deferred" ? <Badge radius={'sm'} color='cyan' variant='light'>{"مؤجلة"}</Badge> : null
                                                    }</>
                                                    : null
                                                }
                                            </Group>
                                        </Group>
                                    }
                                    styles={{
                                        itemBody: {
                                            background: "#fff",
                                            padding: 10
                                        },
                                        itemTitle: {
                                            fontSize: 14
                                        }
                                    }}
                                >
                                    <Text c="dimmed" size="sm">{item?.note}</Text>
                                    <Group justify='flex-end' align='center'>
                                        <Text size="xs" mt={4}>{dayjs(item?.createdAt).format("YYYY-MM-DD HH:mm:ss")}</Text>
                                    </Group>
                                </Timeline.Item>
                            ))}
                        </Timeline>
                        : null
                    }
                    
                </Tabs.Panel>
            </Tabs>
        </Drawer>
    );
}